// Yandex.Metrika counter
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(65212666, "init", {
	clickmap:true,
	trackLinks:true,
	accurateTrackBounce:true,
	webvisor:true
});
// /Yandex.Metrika counter

// Google Analytics
	window.dataLayer = window.dataLayer || [];
	window.gtag = function gtag(){dataLayer.push(arguments);}
	window.gtag('js', new Date());
	window.gtag('config', 'UA-171634414-1');
// /Google Analytics

